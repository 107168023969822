import PropTypes from "prop-types";
import List from "app/pages/.shared/List";
import "./AlertMessage.scss";
import classNames from "classnames";
import { ALERT_TYPE } from "app/constants";
import { alertTypeProptypes } from "app/utils/propTypes";
import IconCircleTick from "app/pages/.shared/static/icons/IconCircleTick";
import IconWarning from "app/pages/.shared/static/icons/IconWarning";
import { useEffect, useRef } from "react";
import { scrollToElement } from "app/utils/scroller";

const AlertMessage = ({
	errors = [],
	message,
	alertType,
	children,
	scrollOnShow = false,
	className,
}) => {
	let icon = <i className="icon icon--info-blue" />;

	if (alertType === ALERT_TYPE.SUCCESS) {
		icon = <IconCircleTick />;
	} else if (alertType === ALERT_TYPE.ERROR_ICON) {
		icon = <IconWarning />;
	} else if (alertType === ALERT_TYPE.ERROR) {
		icon = false;
	}

	const ref = useRef();

	useEffect(() => {
		if (ref.current && scrollOnShow) {
			scrollToElement({ element: ref.current, offset: -20, disableSmoothScroll: false });
		}
	}, [ref.current, scrollOnShow]);

	return (
		<div
			ref={ref}
			role="alert"
			className={classNames("alert-message", {
				[className]: true,
				"alert-message--error": [ALERT_TYPE.ERROR, ALERT_TYPE.ERROR_ICON].includes(
					alertType
				),
				"alert-message--success": alertType === ALERT_TYPE.SUCCESS,
			})}
		>
			{message && (
				<div className="alert-message__message">
					{icon}
					<div className="alert-message__text" data-testid="alert-message">
						{message}
					</div>
				</div>
			)}
			{children}
			{errors.length > 0 ? <List items={errors} /> : false}
		</div>
	);
};

AlertMessage.propTypes = {
	className: PropTypes.string,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	errors: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
	alertType: alertTypeProptypes,
	scrollOnShow: PropTypes.bool,
};

export default AlertMessage;
