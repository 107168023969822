import { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import HelpDeskPhoneNumberContainer from "app/pages/.shared/HelpDeskPhoneNumber/HelpDeskPhoneNumberContainer.jsx";
import FooterLogos from "app/pages/Footer/FooterLogos/FooterLogos";
import ShopSwitcherContainer from "app/pages/Home/ShopSwitcher/ShopSwitcherContainer";
import SocialNetworksList from "app/pages/.shared/SocialNetworksList/SocialNetworksList";
import LanguageSwitcher from "app/pages/.shared/LanguageSwitcher/LanguageSwitcher";
import {
	signupPageAuthShape,
	partnerRedirectionLinkShape,
	socialNetworksLinksShape,
} from "app/utils/propTypes";
import HelpLink from "app/pages/Footer/HelpLink/HelpLink";
import NoteCasinoContainer from "app/pages/Footer/NoteCasino/NoteCasinoContainer";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import "./Footer.scss";
import ReassurancePayment from "app/pages/.shared/ReassurancePayment/ReassurancePayment";
import IconEngagementPaiementSecurise from "app/pages/.shared/static/icons/IconEngagementPaiementSecurise";
import RelativeLink from "app/pages/.shared/RelativeLink";
import { displayable } from "app/utils/utils";
import { BRANDS, BRANDS_WITH_ACCESS_TO_MY_BOOKING } from "app/constants";

const Footer = ({
	partnerRedirectionLink,
	socialNetworksLinks,
	shop,
	cgvDocumentName,
	legalesDocumentName,
	confidentialiteDocumentName,
	signupPageAuth = {},
	showShopSwitcher,
	showPfsMessage,
	useSVA,
	additionalNote = [],
	feedbackLink,
	showCasinoNote,
	resolution,
	showConfigureCookieLink,
	paymentMethods = [],
	additionalLinks = [],
	brandDisplayName,
	isWhiteLabel = false,
	preSalesPlanningUrl,
	hideContactUsSection = false,
	brandCode,
}) => {
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const showSocialNetworksLinks =
		socialNetworksLinks && (socialNetworksLinks.instagram || socialNetworksLinks.facebook);

	let linkToBookingCancellation = isWhiteLabel
		? "/account/mybookings"
		: `https://booking.perfectstay.com/${shop}`;

	if (brandCode === BRANDS.PS) {
		linkToBookingCancellation = `https://booking.perfectstay.com/${shop}`;
	} else if (BRANDS_WITH_ACCESS_TO_MY_BOOKING.includes(brandCode)) {
		linkToBookingCancellation = "/booking-search";
	}

	return (
		<div className="footer">
			<footer className="footer__footer-block">
				<div className="footer__container">
					<div className="footer__footer">
						<div className="footer__social">
							{showPfsMessage && (
								<>
									<div className="footer__partner">
										<div className="footer__partner-title">
											<FormattedMessage id="footer.partner.title" />
										</div>
										<div className="footer__partner-text">
											<FormattedMessage id="footer.partner.text" />
										</div>
										<div className="footer__partner-logo">
											<i className="icon icon--logo-perfectstay-white" />
										</div>
									</div>
									<hr className="footer__section-separator" />
								</>
							)}
							{paymentMethods?.length > 0 && (
								<>
									<div className="footer__reassurance-payment-headline">
										<IconEngagementPaiementSecurise width={35} height={35} />
										<div className="footer__reassurance-payment-label">
											<FormattedMessage id="general.secure.payment" />
										</div>
									</div>
									<ReassurancePayment paymentMethods={paymentMethods} />
									<hr className="footer__section-separator" />
								</>
							)}
							<div className="social">
								{showSocialNetworksLinks && (
									<SocialNetworksList socialNetworksLinks={socialNetworksLinks} />
								)}
							</div>
						</div>

						{showSocialNetworksLinks && isMobile && (
							<hr className="footer__separator" />
						)}

						<div className="footer__contact">
							{!hideContactUsSection && (
								<>
									<div className="footer-contact">
										<div className="footer-contact__headline">
											<FormattedMessage id="footer.contact.headline" />
										</div>
										<HelpDeskPhoneNumberContainer useSVA={useSVA} />
										<div className="footer-contact__item">
											<RelativeLink
												rel="noopener noreferrer"
												target="_blank"
												to={{
													pathname: preSalesPlanningUrl,
												}}
											>
												<FormattedMessage id="footer.contact.sales.planning.label" />
											</RelativeLink>
										</div>
										{isMobile && <hr className="footer__section-separator" />}
									</div>

									{showShopSwitcher && (
										<>
											<hr className="footer__section-separator" />
											<div
												className="footer__language"
												data-testid="footer-language"
											>
												<ShopSwitcherContainer
													renderComponent={props => (
														<LanguageSwitcher {...props} />
													)}
												/>
											</div>
										</>
									)}
								</>
							)}
						</div>

						<div className="footer__menu">
							<div className="footer__row">
								<ul className="footer-menu">
									{partnerRedirectionLink &&
										partnerRedirectionLink.url &&
										partnerRedirectionLink.label && (
											<li className="footer-menu__item">
												<a
													className="relative-link"
													data-testid="partner-redirection-link"
													rel="noopener noreferrer"
													target="_blank"
													href={partnerRedirectionLink.url}
												>
													{partnerRedirectionLink.label}
												</a>
											</li>
										)}
									{feedbackLink && brandDisplayName && (
										<li className="footer-menu__item">
											<a
												className="relative-link"
												rel="noopener noreferrer"
												target="_blank"
												href={feedbackLink}
											>
												<FormattedMessage
													id="footer.feedback.label"
													values={{ brandName: brandDisplayName }}
												/>
											</a>
										</li>
									)}
									{cgvDocumentName && (
										<li className="footer-menu__item">
											<a
												className="relative-link"
												rel="noopener noreferrer"
												target="_blank"
												href={cgvDocumentName}
											>
												<FormattedMessage id="menu.item.cgv" />
											</a>
										</li>
									)}
									{legalesDocumentName && (
										<li className="footer-menu__item">
											<a
												className="relative-link"
												rel="noopener noreferrer"
												target="_blank"
												href={legalesDocumentName}
											>
												<FormattedMessage id="menu.item.legales" />
											</a>
										</li>
									)}
									{confidentialiteDocumentName && (
										<li className="footer-menu__item">
											<a
												className="relative-link"
												rel="noopener noreferrer"
												target="_blank"
												href={confidentialiteDocumentName}
											>
												<FormattedMessage id="menu.item.confidentialite" />
											</a>
										</li>
									)}

									{showConfigureCookieLink && (
										<li className="footer-menu__item">
											<button
												type="button"
												id="ot-sdk-btn"
												className="relative-link ot-sdk-show-settings"
											>
												<FormattedMessage id="menu.item.cookies" />
											</button>
										</li>
									)}
								</ul>
								<ul className="footer-menu">
									<li className="footer-menu__item">
										<HelpLink />
									</li>

									<li className="footer-menu__item">
										<RelativeLink
											rel="noopener noreferrer"
											data-testid="link-to-booking-cancellation"
											target="_blank"
											to={{
												pathname: linkToBookingCancellation,
											}}
										>
											<FormattedMessage id="menu.item.cancel.reservation" />
										</RelativeLink>
									</li>

									{Array.isArray(additionalLinks) &&
										additionalLinks.length > 0 &&
										additionalLinks
											.filter(link => displayable(link))
											.map(link => (
												<li key={link.name} className="footer-menu__item">
													<RelativeLink
														data-testid={`additional-link-${link.name}`}
														rel="noopener noreferrer"
														target={
															link.openInNewWindow
																? "_blank"
																: "_self"
														}
														to={{ pathname: link.URL }}
													>
														{link.name}
													</RelativeLink>
												</li>
											))}
								</ul>
							</div>

							<hr className="footer__section-separator" />
							<div className="footer__row">
								<div className="footer-pictos" data-testid="footer-pictos">
									<FooterLogos shop={shop} />
								</div>
							</div>
						</div>
					</div>

					{Array.isArray(additionalNote) &&
						additionalNote.length > 0 &&
						additionalNote
							.filter(note => displayable(note))
							.map(note => {
								return note.URL ? (
									<RelativeLink
										data-testid={`additional-note-${note.name}`}
										rel="noopener noreferrer"
										target={note.openInNewWindow ? "_blank" : "_self"}
										to={{ pathname: note.URL }}
										className="footer__note"
									>
										{note.name}
									</RelativeLink>
								) : (
									<div className="footer__note">{note.name}</div>
								);
							})}

					{showCasinoNote && (
						<div className="footer__note">
							<NoteCasinoContainer />
						</div>
					)}

					{signupPageAuth.note && (
						<div className="footer__note">{signupPageAuth.note}</div>
					)}
				</div>
			</footer>
		</div>
	);
};

Footer.propTypes = {
	partnerRedirectionLink: partnerRedirectionLinkShape,
	socialNetworksLinks: socialNetworksLinksShape,
	partnerRedirectionUrlFooter: PropTypes.string,
	showConfigureCookieLink: PropTypes.bool,
	cgvDocumentName: PropTypes.string,
	legalesDocumentName: PropTypes.string,
	shop: PropTypes.string,
	confidentialiteDocumentName: PropTypes.string,
	signupPageAuth: signupPageAuthShape,
	hasCasinoNote: PropTypes.bool,
	showShopSwitcher: PropTypes.bool,
	useSVA: PropTypes.bool,
	showPfsMessage: PropTypes.bool,
	additionalNote: PropTypes.array,
	feedbackLink: PropTypes.string,
	showCasinoNote: PropTypes.bool,
	showReassurancePayment: PropTypes.bool,
	resolution: PropTypes.string,
	displayPhone: PropTypes.arrayOf(PropTypes.string),
	paymentMethods: PropTypes.array,
	additionalLinks: PropTypes.array,
	brandDisplayName: PropTypes.string,
	brandCode: PropTypes.string,
	isWhiteLabel: PropTypes.bool,
	preSalesPlanningUrl: PropTypes.string,
	hideContactUsSection: PropTypes.bool,
};

export default memo(Footer);
